import React, { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { useDispatch } from 'react-redux';

import { ProfileFormValues } from 'types/profile';

import ProfileSetupForm from 'components/profile-setup-form/profile-setup-form.component';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';
import { FormikHelpers } from 'formik';
import { accountRegisterProfileRoutine } from 'state/account/account.routines';

const GetStarted = (): ReactElement => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const imageData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/feather-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const handleSubmitProfile = (
        profile: Partial<ProfileFormValues>,
        helpers: FormikHelpers<Partial<ProfileFormValues>>
    ) => {
        dispatch(
            accountRegisterProfileRoutine.trigger({
                formValues: profile,
                formHelpers: helpers,
                errorMessage: t('registration.errors.emailAlreadyOnFile')
            })
        );
    };

    return (
        <WorkflowLayout
            backgroundImage={imageData.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('profile.eyebrowText') }}
        >
            <WorkflowLayoutFormWrapper eyebrowText={t('profile.eyebrowText')} title={t('registration.finalizeTitle')}>
                <ProfileSetupForm onSubmit={handleSubmitProfile} />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default GetStarted;
